import React from "react"

import Layout from "../components/layout"
import Head from "../components/head"

const NotFound = () => {
  return (
    <Layout showStepScroller={false}>
      <Head title="Not found" />
      <h1>NotFound page</h1>
    </Layout>
  )
}

export default NotFound
